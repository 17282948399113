import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField,FormControl,Select} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';

import classNames from 'classnames';

import theme,{styles} from '../layout/theme';
import Utils from '../api/api';

export class CourseList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
      filter: '',
      categories: [],
      statuses: ['Draft','Paused','Publish'],
      status: '',
      category:'',
    }
  }
  componentDidMount(){
    const $this = this;
    Utils.getListData('course-offline-categories','all',100,'0,0',null,1,function(data){
      if (data.status === 'success') {
        $this.setState({categories: data.results});
      }
    });
  }
  handleSearch = (event) => {
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd = (event) => {
    this.context.router.history.push('/course-offline/add');
  }
  filterData(e){
    const $this = this,
          state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state,function(){
      $this.handleFilter();
    });
  }
  handleFilter() {
    let f = this.state.filter;

    if (f === '') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if (this.state.category !== ''){
      f.category = this.state.category;
    } else {
      if (f.category !== undefined) {
        delete f.category;
      }
    }

    if (this.state.status !== ''){
      f.status = parseInt(this.state.status);
    } else {
      if(f.status!==undefined) {
        delete f.status;
      }
    }

    console.log(f);
    this.setState({filter:JSON.stringify(f)});
  }

  render(){
    const $this = this,
          classes = this.props.classes;
    //console.log(this.state.categories)
    var source = {
      thead: [{title:'Khóa học',value:'nameVN'},{title:'Hình đại diện',value:'photo'},{title:'Nhóm',value:'category'},{title:'Học phí/Học Phí KM',value:'price'},{title:'Trạng thái',value:'status'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          nameVN: n.nameVN,
          //url: <div>{n._id}<br /><br />{n.urlVN}<br/>{n.urlEN}</div>,
          photo: <div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.image !== '' && n.image !== undefined ? Utils.fixImagePath(n.image) : Utils.fixImagePath('/data/upload/images/no_photo.png')} title={n.nameVN} /></div>,
          category: $this.state.categories.length&&$this.state.categories.map(x=>{
            return x._id==n.category?x.nameVN:'';
          }),
          price: <div><p>{n.price}</p><p>{n.priceSale}</p></div>,
          status: n.status==0?'Draft':(n.status==1?'Paused':'Public')
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục khóa học Offline</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label}>Khóa học</Typography>
                  <Select
                    native
                    id="category"
                    value={$this.state.category}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.categories.map((item, index) => {
                      return (
                        <option className={classNames(item.parent===''?'root':'')} key={index + 1} value={item._id}>
                          {item.nameVN}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    id="status"
                    value={$this.state.status}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.statuses.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col4}>
                  <TextField type="search" id="keyword" label="Tìm kiếm" className={classNames(classes.textField + ' form-control-no-bottom')} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}/>
                </FormControl>
              </FormGroup>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={this.handleSearch}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={this.handleAdd}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='courses' apiSingle='course' apiTable='courses' apiDuplicate={true} router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} apiFilter={this.state.filter} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

CourseList.propTypes = {
  classes: PropTypes.object.isRequired,
};
CourseList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(CourseList);
