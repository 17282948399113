import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button, Fab, FormLabel,Toolbar, Select, Chip} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {FormGroup, FormControl} from '@material-ui/core';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
//import Media from './media';
import ImageControl from './shares/react_image_control';
import ReactAutoSuggest from './shares/react_auto_suggest';


import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';



// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';



// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }


class CourseDetail extends Component {
  constructor(props) {
    super(props);
    var obj = {image:'',title:'',desc:'',url:''}
    this.state = {
      categories: [],
      lecturers: [],
      banners:[],
      status: ['Draft','Paused','Public'],
      course: {
        banner:'',
        category:'',
        image: '',
        images: [],
        price: 0,
        priceSale: 0,
        urlVN: '',
        urlEN: '',
        timeLength: 1,
        lecturers: [],
        nameVN:'',
        nameEN:'',
        generalVN:'',
        generalEN:'',
        detailVN:'',
        detailEN:'',
        priceVN:'',
        priceEN:'',
        descriptionVN:'',
        descriptionEN:'',
        status:0,
        getflyId: ''
      },
      error: {
        status: false,
        vn: false,
        en: false,
        nameVN_error: false,
        nameEN_error: false,
        detailVN_error: false,
        detailEN_error: false,
        category_error: false,
        lecturer_error: false
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false,
      options: {'empty':'empty'},
      getflyCourses: []
    };
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData('banners','all',100,'0,0',null,1,function(data) {
      //console.log(data);
      if (data.status === 'success') {
        $this.setState({banners: data.results});
      }
    });

    Utils.getListData("course-offline-categories", 1, 200, "0,0", null, 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ categories: data.results });
      }
    });

    Utils.getListData("lecturers", 1, 200, "0,0", JSON.stringify({isOnline:'false',status:1}), 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ lecturers: data.results });
      }
    });

    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });

    Utils.getData('getfly/schedule/categories','',function(data) {
      //console.log(data);
      if(data.status==='success' && data.results.length) {
        // let arr = data.results.filter(x=> x.field_name === 'gia_ng_vien');
        // //console.log(arr);
        // if(arr.length) {
        //   $this.setState({getflyLectureId: arr[0].options});
        // }
        $this.setState({getflyCourses: data.results});
      }
    });

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        try {
          Utils.getSingleData('courses',params.id, function(res) {
            if (res.status === 'success' && res.results!==undefined) {
              var data = {}, item = res.results;
              for(var property in item) {
                if(item.hasOwnProperty(property)) {
                  data[property] = item[property];
                }
              }
              data._id = item._id;
              if(data.urlVN===undefined)data.urlVN = '';
              if(data.urlEN===undefined)data.urlEN = '';
              if(data.status===undefined)data.status = 0;
              if(data.priceSale===undefined||data.priceSale==='')data.priceSale = data.price;
              if(data.images===undefined || data.images.length===0) data.images = [''];
              if (!Array.isArray(data.lecturers)) data.lecturers = [data.lecturers];

              $this.setState({course: data},function(){

              });
            }
          });
        } catch (error) {
          console.log(error);
        }
      });
    }
  }
  onEditorStateChange(field,value){
    var course = this.state.course,
        error = this.state.error;
    course[field] = value;
    error[field+'_error'] = value=='';
    var locale = field.replace('detail','');
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = value;
    // Retrieve the text property of the element (cross-browser support)
    var text = temporalDivElement.textContent || temporalDivElement.innerText || "";
    course['description'+locale] = text.substring(0,200);

    this.setState({course:course,error:error});
  }
  addMoreItem(){
    var course = this.state.course,
        data = course.images,
        l = data.length;
    if(l<50){
      data.push('');
      course.images = data;
      this.setState({course:course},function(){

      });
    }
  }
  removeItem(index){
    var course = this.state.course,
        data = course.images,
        l = data.length;
        if(l>1){
          data.splice(index,1);
          course.images = data;
          this.setState({course:course},function(){

          });
        }
  }
  handleImageChange(index,value){
    //console.log(value);
    var $this = this,
        course = this.state.course;
    course.images[index] = value;
    this.setState({course:course});
  }
  handleCover(value) {
    var $this = this,
        course = this.state.course;
    course.image = value;
    this.setState({course:course});
  }
  handleFieldChange(e) {
    var $this = this,
        course = $this.state.course;
    course[e.target.id] = e.target.value;
    if(e.target.type==='textarea'){
      course[e.target.id] = e.target.value.substring(0,200);
    }
    this.setState({course:course});
  }
  handleValidate(e,flag,field) {
    var $this = this,
        course = $this.state.course,
        obj = $this.state.error;

    course[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';



    if(field===undefined){
      if (e.target.value.trim() === '' || e.target.value === null) {
        obj[p] = true;
      } else {
        obj[p] = false;
      }
      if(e.target.id==='nameVN'){
        var urlVN = Utils.getFriendlyURL(e.target.value.trim());
        course.urlVN = urlVN;
      }
      if(e.target.id==='nameEN'){
        var urlEN = Utils.getFriendlyURL(e.target.value.trim());
        course.urlEN = urlEN;
      }

      if(e.target.id.indexOf('price')!==-1){
        course[e.target.id] = parseFloat(e.target.value.trim());
      }

      if(!obj[flag])obj[flag] = obj[p];

    } else {
      course[field] = e.target.value;
      obj[field+'_error'] = e.target.value.length===0;
      if(!obj.status) obj.status = obj[field+'_error'];

    }


    this.setState({course: course, error:obj}, () => {
    });
  }
  selectImg(index,value){
    var $this = this,
        course = this.state.course;
    course.image[index] = value;
    this.setState({course:course},function(){
      $this.handleClose();
    });
  }
  _save(){
    var $this = this;
    var data = this.state.course;

    data.price = parseFloat(data.price);
    data.priceSale = parseFloat(data.priceSale);
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('course',data, function(res) {
        //console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/course-offline/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('course',data, function(res) {
        if (res.status === 'success') {
          $this.context.router.history.push('/course-offline/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = $this.state.error;
    var course = $this.state.course;
    if (obj.status) {
      return;
    } else {
      obj['category_error'] = course.category === '';
      obj['lecturer_error'] = course.lecturers.length === 0;
      obj.status = obj.category_error || obj.lecturer_error;

      obj['name'+flag.toUpperCase()+'_error'] = course['name'+flag.toUpperCase()] === '';
      obj['detail'+flag.toUpperCase()+'_error'] = course['detail'+flag.toUpperCase()] === '';
      obj[flag] = obj['name'+flag.toUpperCase()+'_error'] || obj['detail'+flag.toUpperCase()+'_error'];

      if(!obj.status) {
        obj.status = obj[flag];
      }

      this.setState({
        error: obj
      },function(){
        if(!obj.status) {
          $this._save();
        }
      });

    }
  }
  handleDeleteLecturer(item,index) {
    var course = this.state.course,
        lecturers = course.lecturers;

    lecturers.splice(index, index + 1);
    course.lecturers = lecturers;
    this.setState({ course: course });
  }
  handleCancel() {
    this.context.router.history.push('/course-offline/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  handleCopy(){
    var data = this.state.course;
    data.nameEN = data.nameVN;
    data.detailEN = data.detailVN;
    data.generalEN = data.generalVN;
    data.priceEN = data.priceVN;
    data.descriptionEN = data.descriptionVN;
    this.setState({
      course: data
    });
  }
  onUpdateLecturer(value,title){

    let c = this.state.course,
        arr = c.lecturers;

    if(arr.indexOf(value)<0){
      arr.push(value);
      c.lecturers = arr;
      this.setState({course:c});
    }
  }
  render(){
    const $this = this;
    const classes = $this.props.classes;
    const value = $this.state.tab;
    const course = $this.state.course;
    const editorConfig = Utils.setupEditor('products',$this.state.options);
    //console.log(course);

    const arr = [];

    $this.state.lecturers.map((x,i)=>{
      arr.push({title:x.fullnameVN,value:x._id});
    });

    const course_lecturers = [];
    if (course.lecturers !== undefined && course.lecturers !== null && course.lecturers.length > 0) {
      course.lecturers.map((item, index) => {
        //console.log(item);
        $this.state.lecturers.map((y,z)=>{
          if(item===y._id) {
            //console.log(y._id);
            course_lecturers.push(
              <Chip
                label={y['fullnameVN']}
                key={z}
                onDelete={() => this.handleDeleteLecturer(item, index)}
                className={classes.chip}
              />
            )
          }
        })
      });
    }

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa khóa học offline</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
          </Tabs>
        </AppBar>
        <form className={classes.form} noValidate>
          <FormGroup className={classes.formRow}>
            <Typography className={classes.label} type="caption">Banner</Typography>
            <select id="banner" className={'select ' + classes.select} value={course.banner} onChange={(event) => this.handleFieldChange(event)}>
              <option value="" key={0}>...</option>
              {this.state.banners.map((item, index) => (
                <option key={index + 1} value={item._id}>{item.name}</option>
              ))}
            </select>
          </FormGroup>
          <ImageControl className="small" label="Hình đại diện" value={course.image} handleChange={(value) => this.handleCover(value)}></ImageControl>
          <Button variant="contained" color="primary" aria-label="add" size="small" onClick={()=>this.addMoreItem()}>
          Thêm hình<AddIcon /></Button>
          {
            course.images.map((x,i)=>{
              return (
                <div key={i} className="item-image-wrapper">
                  <Fab aria-label="delete" color="secondary" size="small" onClick={()=>this.removeItem(i)}>
                    <DeleteIcon />
                  </Fab>
                  <ImageControl className="small" value={x} handleChange={(value) => this.handleImageChange(i,value)}></ImageControl>
                </div>
              )
            })
          }
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col4}>
              <Typography className={classes.label}>Nhóm khóa học</Typography>
              <Select
                native
                className={classes.selectMultiLevel}
                id="category"
                value={course.category}
                onChange={event => this.handleValidate(event,null)}
              >
                <option value="" key={0}>
                  ...
                </option>
                {this.state.categories.map((item, index) => {
                  return (
                    <option className={classNames(item.parent===''?'root':'')} key={index + 1} value={item._id}>
                      {item.nameVN}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.col4}>
              <Typography className={classes.label}>Giảng viên</Typography>
              <FormControl className={classes.chipRow}>
                {
                  course_lecturers
                }
              </FormControl>
              <ReactAutoSuggest value={course.lecturers} className={classes.formRow} data={arr} onChange={(v,t)=>this.onUpdateLecturer(v,t)}></ReactAutoSuggest>
            </FormControl>
            <FormControl className={classes.col6}>
            {
              this.state.getflyCourses!==undefined ? (
                <React.Fragment>
                  <Typography className={classes.label}>Đồng bộ với GetFly System:</Typography>
                  <Select native id='getflyId' value={course.getflyId} onChange={event => this.handleFieldChange(event)}>
                    <option value="" key={0}>...</option>
                    {this.state.getflyCourses.map((item, index) => {
                      return (
                        <option key={index + 1} value={item.category_id}>
                          {item.category_name}
                        </option>
                      );
                    })}
                  </Select>
                </React.Fragment>
              ):''
            }
            </FormControl>
          </FormGroup>
          <FormGroup className={classes.formRowInline}>
            <FormControl className={classes.col3}>
              <TextField id="price" type="number" label={language.VN.price} className={classes.textField + ' form-control-no-bottom'} value={course.price} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            </FormControl>
            <FormControl className={classes.col3}>
              <TextField id="priceSale" type="number" label={language.VN.priceSale} className={classes.textField + ' form-control-no-bottom'} value={course.priceSale} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            </FormControl>
            <FormControl className={classes.col3}>
              <TextField id="timeLength" type="number" label="Thời gian (tháng)" className={classes.textField + ' form-control-no-bottom'} value={course.timeLength} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            </FormControl>
            <FormControl className={classes.col3}>
              <Typography className={classes.label}>Trạng thái</Typography>
              <Select
                native
                className={classes.selectMultiLevel}
                id="status"
                value={course.status}
                onChange={event => this.handleFieldChange(event)}
              >
                <option value="" key={0}>
                  ...
                </option>
                {this.state.status.map((item, index) => {
                  return (
                    <option key={index + 1} value={index}>
                      {item}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </FormGroup>
        </form>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <TextField id="nameVN" label={language.VN.name} className={classes.textField} value={course.nameVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.nameVN_error} margin="normal"/>
            <TextField id="urlVN" label="Link [SEO]" className={classes.textField} value={course.urlVN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <TextField id="descriptionVN" rows="5" multiline={true} label="Meta Description (max. length: 200 characters)" className={classes.textField} value={course.descriptionVN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormLabel className={classes.label_shrink} >Tab tổng quan</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.generalVN}
              onModelChange={(value)=>this.onEditorStateChange('generalVN',value)}
            />
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailVN_error}>Tab nội dung</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.detailVN}
              onModelChange={(value)=>this.onEditorStateChange('detailVN',value)}
            />
            <FormLabel className={classes.label_shrink} >Tab học phí</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.priceVN}
              onModelChange={(value)=>this.onEditorStateChange('priceVN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameEN" label={language.EN.name} className={classes.textField} value={course.nameEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.nameEN_error} margin="normal"/>
            <TextField id="urlEN" label="Link [SEO]" className={classes.textField} value={course.urlEN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <TextField id="descriptionEN" rows="5" multiline={true} label="Meta Description (max. length: 200 characters)" className={classes.textField} value={course.descriptionEN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormLabel className={classes.label_shrink} >Tab general</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.generalEN}
              onModelChange={(value)=>this.onEditorStateChange('generalEN',value)}
            />
            <FormLabel className={classes.label_shrink} >Tab fee</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.priceEN}
              onModelChange={(value)=>this.onEditorStateChange('priceEN',value)}
            />
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailEN_error}>{language.EN.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.detailEN}
              onModelChange={(value)=>this.onEditorStateChange('detailEN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="nameKR" label={language.KR.name} className={classes.textField} value={course.nameKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.nameKR_error} margin="normal"/>
            <TextField id="descriptionKR" rows="5" multiline={true} label="Meta Description (max. length: 200 characters)" className={classes.textField} value={course.descriptionKR} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormLabel className={classes.label_shrink} >Tab 혜택</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.generalKR}
              onModelChange={(value)=>this.onEditorStateChange('generalKR',value)}
            />
            <FormLabel className={classes.label_shrink} >Tab 학비</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.priceKR}
              onModelChange={(value)=>this.onEditorStateChange('priceKR',value)}
            />
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailKR_error}>{language.KR.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={course.detailKR}
              onModelChange={(value)=>this.onEditorStateChange('detailKR',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    )
  }
}

CourseDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
CourseDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(CourseDetail);
